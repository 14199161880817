<template>
  <div class="overflow-hidden">
    <SiteNav />
    <div class="">
      <section
        class="hero relative overflow-hidden pt-12 pb-20 lg:pt-20 lg:pb-24"
      >
        <div style="z-index: -1" class="absolute -top-56 -right-10">
          <img src="./assets/blur.svg" />
        </div>
        <div class="l-container">
          <div
            class="text-center flex flex-col-reverse lg:text-left lg:grid grid-cols-2 gap-8 items-center"
          >
            <div class="">
              <p class="tagline">HR STORES</p>
              <h1 class="heading level-2 mb-4">
                Shop computers, phones, company brand items & many more for
                employees
              </h1>
              <p class="text-summary w-4/5 mx-auto lg:mx-0 mb-6">
                Pay as low as {{ formatAmount(70000) }} per month for laptops
                and gadgets.
              </p>
              <SiteButton to="/login" color="primary" shadow>
                Get started
              </SiteButton>
            </div>
            <div class="max-w-2xl mx-auto">
              <img src="./assets/img-3.png" />
            </div>
          </div>
        </div>
      </section>

      <section class="freedom-section py-12 lg:py-20">
        <div class="l-container">
          <div class="flex flex-col lg:grid grid-cols-2 lg:gap-16 items-center">
            <div>
              <img src="./assets/img-4.png" />
            </div>
            <div
              class="text-center max-w-md mx-auto mb-10 lg:mb-0 lg:max-w-none lg:text-left"
            >
              <h2 class="heading level-3 mb-4">
                Freedom to choose from amazing varieties
              </h2>
              <div class="flex flex-col gap-5 mb-5">
                <p class="text-summary">
                  You have the freedom to choose your preferred company
                  merchandise with our user-friendly interface.
                </p>
                <p class="text-summary">
                  From work tools to office essentials to branded merch and
                  wellness items, you will love the variety HR Stores provide.
                </p>
              </div>
              <SiteButton
                color="primary"
                class="flex text-sm md:text-base items-center gap-2"
                shadow
                to="/login"
              >
                Get started Browse our collection of items
                <i class="pi pi-arrow-right text-xs"></i>
              </SiteButton>
            </div>
          </div>
        </div>
      </section>

      <section class="what-we-offer-section py-12 lg:py-24 lg:pb-28">
        <div class="l-container">
          <div class="flex flex-col gap-6">
            <h2 class="heading text-center level-3 mb-4">What we offer</h2>

            <div
              class="grid grid-cols-1 md:grid-cols-3 gap-y-8 gap-x-10 w-full md:w-11/12 mx-auto"
            >
              <Card
                v-for="(item, i) in offers"
                :key="`item-${i}`"
                :data="item"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
    <SiteFooter />
  </div>
</template>

<script setup>
import Card from "../../components/Card.vue";
import SiteFooter from "../../components/SiteFooter.vue";
import SiteNav from "../../components/SiteNav.vue";
import SiteButton from "../../components/SiteButton.vue";

import Pentagram from "./assets/Pentagram.svg";
import PentagramHover from "./assets/Pentagram-Active.svg";
import CurrencyNgn from "./assets/CurrencyNgn.svg";
import CurrencyNgnHover from "./assets/CurrencyNgn-Active.svg";
import OfficeChair from "./assets/OfficeChair.svg";
import OfficeChairHover from "./assets/OfficeChair-Active.svg";

import { helperFunctions } from "@/composable/helperFunctions";
const { formatAmount } = helperFunctions;

const offers = [
  {
    title: "Quality and Authentic",
    description:
      "All items on our store are highly authentic with top-notch quality.",
    icon: Pentagram,
    hoverIcon: PentagramHover,
  },
  {
    title: "Flexible payment options",
    description: "Enjoy unbelievable flexibility with payments.",
    icon: CurrencyNgn,
    hoverIcon: CurrencyNgnHover,
  },
  {
    title: "Specially curated office essentials",
    description: "We have made provision for all your office essentials.",
    icon: OfficeChair,
    hoverIcon: OfficeChairHover,
  },
];
</script>

<style lang="scss" scoped>
@import url("../../styles/www.scss");

.hero {
  .heading {
    color: #292a29;
  }
}

.freedom-section {
  background: #f8f8f8;
  .heading {
    font-family: "Roboto";
    span {
      color: #ea4e4b;
    }
  }
}

.what-we-offer-section {
  .heading {
    font-family: "Roboto";
  }
}
</style>
