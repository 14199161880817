<template>
  <div
    v-if="data"
    @mouseover="isHover = true"
    @mouseout="isHover = false"
    class="site_card cursor-pointer group w-full hover:bg-white p-5 rounded-2xl flex flex-col gap-4"
  >
    <div
      class="w-14 h-14 rounded-lg bg-white border border-newAsh group-hover:bg-primary flex items-center justify-center"
    >
      <img :src="cardIcon" alt="" />
    </div>

    <div class="flex flex-col gap-2">
      <h4 class="text-lg sm:text-xl text-dark-900">{{ data.title }}</h4>
      <p class="text-sm sm:text-base text-dark-700">
        {{ data.description }}
      </p>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";

const props = defineProps({
  data: {
    type: Object,
    default: () => ({
      title: "",
      description: "",
      icon: null,
      hoverIcon: null,
    }),
  },
});

const cardIcon = computed(() => {
  if (!props.data) return "";
  return isHover.value ? props.data.hoverIcon : props.data.icon;
});

const isHover = ref(false);
</script>

<style lang="scss" scoped>
@import url("../styles/www.scss");

.site_card {
  background: #f8f8f8;
  border: 1px solid #f2f1f1;
}

.site_card:hover {
  background: #ffff;
  box-shadow: 0px 12px 56px 0px #061c3d1f;
}
</style>
